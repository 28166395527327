import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not Found" />
		<div className="auxiliary-wrapper">
			<div className="container">
				<h1>NOT FOUND</h1>
				<p className="my-4 lead">
					The page you are looking for does not exist.
				</p>
				<Link to="/" className="btn btn-primary">
					Take Me Home
				</Link>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
